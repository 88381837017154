import { Suspense, useRef, useState } from "react";
import Select, { components } from "react-select";
import { Formik, ErrorMessage } from "formik";
import clsx from "clsx";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import "react-datetime/css/react-datetime.css";
import { useLazyLoadQuery } from "react-relay";
import * as yup from "yup";
import Moment from "moment";
import Datetime from "react-datetime";
import { createVersion } from "../models/_createVersion";
import { CommonQuery_getBinariesQuery } from "../models/__generated__/CommonQuery_getBinariesQuery.graphql";
import { CommonQuery_getRscQuery } from "../models/__generated__/CommonQuery_getRscQuery.graphql";
import { CommonQuery_getScriptsQuery } from "../models/__generated__/CommonQuery_getScriptsQuery.graphql";
import { CommonQuery_getConfigVersionQuery } from "../models/__generated__/CommonQuery_getConfigVersionQuery.graphql";
import UpdateScriptTypeAndRscFileInputSelectOrUpload from "./helpers/UpdateScriptTypeAndRscFileInputSelectOrUpload";
const validationSchema = yup.object({
  versionNo: yup.string().required("Version number is required"),
  launchDate: yup.date().required("Launch date is required"),
  selectRscFile: yup.array().when("isRscFile", {
    is: (isRscFile :boolean) => !isRscFile,
    then: yup.array().min(1, "Select at least one RSC file"),
  }),
  selectScriptFile: yup.string().when("isUpdateFile", {
    is: (isUpdateFile: boolean) => !isUpdateFile,
    then: yup.string().required("Select an update script file"),
  }),
});
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <label className="form-check form-check-sm form-check-custom form-check-solid align-items-center">
          <input
            className="form-check-input ms-2"
            type="checkbox"
            readOnly
            checked={props.isSelected}
          />
          <span className="ms-2 fs-5 form-check-label">{props.label}</span>
        </label>
      </components.Option>
    </div>
  );
};


const createVersionValues: any = {
  binaryCommit: "",
  configVersion: "",
  versionNo: "",
  launchDate: "",
  endDate: "",
  selectRscFile: [],  
  selectScriptFile: "",
  isUpdateFile: false,
  isRscFile: false,
  isLatestUpdate: false,
};

const CreateVersionPage = (props: any) => {
  const {
    getBinaries,
    getConfigVersions,
    getRscFiles,
    getScripts,
    refreshBinary,
    refreshConfig,
    refreshScript,
    refreshRsc,
  } = props;
  const resetBinaryCommit = useRef<any>(null);
  const resetLaunchDate = useRef<any>(null);
  const resetEndDate = useRef<any>(null);
  const resetDate = useRef<any>(null);
  const [configSelected, setConfigSelected] = useState<any>(false);
  const [uploadScriptFile, setUploadScriptFile] = useState<any[]>([]);
  const [uploadRscFile, setUploadRscFile] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const formRef = useRef<any>();
  // eslint-disable-next-line

  const binariesRecord = useLazyLoadQuery<CommonQuery_getBinariesQuery>(
    getBinaries,
    {}
  );

  const binariesResult: any = binariesRecord?.getBinaries;

  const configVersionRecord =
    useLazyLoadQuery<CommonQuery_getConfigVersionQuery>(getConfigVersions, {});
  const configVersionResult: any = configVersionRecord?.getConfigVersion;

  const selectScriptRecord = useLazyLoadQuery<CommonQuery_getScriptsQuery>(
    getScripts,
    {}
  );
  const selectScriptResult: any = selectScriptRecord?.getScripts;

  const rscRecord = useLazyLoadQuery<CommonQuery_getRscQuery>(getRscFiles, {});
  const rscFileResult: any = rscRecord?.getRsc;

  const onUploadFile = (e: any) => {
    let updateFiles = [];
    let id = e.target.id;
    for (let i = 0; i < e.target.files.length; i++) {
      updateFiles.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      if (id === "rsc_file") {
        reader.onloadend = () => {
          setUploadRscFile((preValue) => {
            return [
              ...preValue,
              {
                name: e.target.files[i].name,
                content: reader.result,
              },
            ];
          });
        };
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
      if (id === "updateScript_file") {
        reader.onloadend = () => {
          setUploadScriptFile((preValue) => {
            return [
              ...preValue,
              {
                name: e.target.files[i].name,
                content: reader.result,
              },
            ];
          });
        };
        if (e.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
    }
  };

  const DeleteSelectFile = (content: any) => {
    setUploadRscFile(uploadRscFile.filter((e: any) => content !== e.content));
    setUploadScriptFile(
      uploadScriptFile.filter((e: any) => content !== e.content)
    );
  };


  return (
    <div>
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Create Version
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark  fs-5">
                  Create Version
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mx-xl-4 mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <Formik
              innerRef={formRef}
              initialValues={createVersionValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setSubmitting }) => {
                let hasErrors = false;
                if (hasErrors) {
                  return false;
                }
                setLoading(true);
                const rscFiles = values.selectRscFile.map(
                  (value: any) => value.value
                );
                const $inputData: any = {
                  versionNo: values.versionNo,
                  binaryCommitPath: values.binaryCommit,
                  configVersionPath: values.configVersion,
                  isLatestUpdate: values.isLatestUpdate,
                  launchDateAndTime: Moment(values.launchDate)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss"),
                  endDateAndTime: values.endDate ? Moment(values.endDate)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss") : null,
                };
                
                if(values.selectScriptFile){
                  $inputData["selectUpdateScript"] = [values.selectScriptFile];
                }
                if(uploadScriptFile?.length !== 0){
                  $inputData["uploadUpdateScript"] = uploadScriptFile;
                }
                if(rscFiles?.length !== 0) {
                  $inputData["selectRscFile"] = rscFiles;
                }
                if(uploadRscFile?.length !== 0) {
                  $inputData["uploadRscFile"] = uploadRscFile;
                }
                const result = await createVersion($inputData);
                if (result?.data?.data?.createVersion) {
                  toast.success("Version created successfully");
                  setLoading(false);
                  setUploadRscFile([]);
                  setUploadScriptFile([]);
                  setConfigSelected(false);
                  resetBinaryCommit.current.setValue("");
                  resetLaunchDate.current.state.inputValue = "";
                  resetEndDate.current.state.inputValue = "";
                  $("input[type=checkbox]").prop("checked", function () {
                    $(this).prop("checked", false);
                  });
                  resetForm();
                } else {
                  setSubmitting(false);
                  setLoading(false);
                  const messages = result?.data?.errors.map(
                    (e: any) => e.message
                  );
                  toast.error(messages.join(","));
                }
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                
                <div>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label required mt-2 fs-4 fw-bold">
                          Version Number
                        </label>
                        <input
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.versionNo) && touched.versionNo,
                          })}
                          name="versionNo"
                          value={values.versionNo}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="versionNo" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 fw-bold">
                          Binary Commit
                          <div
                            onClick={() => refreshBinary()}
                            className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
                          >
                            <i className="fa fa-refresh fs-4 mb-1 spin"></i>
                          </div>
                        </label>
                        <Select
                          ref={resetBinaryCommit}
                          classNamePrefix="my-react-select"
                          className={clsx("react-select")}
                          name="binaryCommit"
                          onChange={(e: any) => {
                            setFieldValue("binaryCommit", e.value);
                          }}
                          placeholder=""
                          options={Array.from(binariesResult).map(
                            (value: any) => {
                              return {
                                value: value.url,
                                label: value.fileName,
                              };
                            }
                          )}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="binaryCommit" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 fw-bold">
                          Config Version
                          <div
                            onClick={() => refreshConfig()}
                            className="btn btn-transparent px-1 ms-3 py-0 text-center justify-content-center align-items-center"
                          >
                            <i className="fa fa-refresh fs-4 mb-1 spin"></i>
                          </div>
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="my-react-select"
                          placeholder=""
                          isMulti
                          name="configVersion"
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          value={configSelected}
                          onChange={(selected: any, e: any) => {
                            const value = selected?.map((e: any) => e.value);
                            setConfigSelected([...selected]);
                            setFieldValue("configVersion", value);
                          }}
                          components={{
                            Option,
                          }}
                          options={Array.from(configVersionResult).map(
                            (value: any) => {
                              return {
                                value: value.url,
                                label: value.fileName,
                              };
                            }
                          )}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="configVersion" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4 required fw-bold">
                          Launch Date Time (UTC)
                        </label>
                        <Datetime
                          ref={resetLaunchDate}
                          className={clsx("date-control", {
                            "is-invalid":
                              Boolean(errors.launchDate) && touched.launchDate,
                          })}
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          closeOnSelect
                          isValidDate={(current) => {
                            let customDate = Moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current >= Moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          value={values.launchDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("launchDate", dt);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-1 ms-1">
                          <ErrorMessage name="launchDate" />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                        <label className="form-label mt-2 fs-4  fw-bold">
                          End Date Time (UTC)
                        </label>
                        <Datetime
                          ref={resetEndDate}
                          className={clsx("date-control", {
                            "is-invalid":
                              Boolean(errors.endDate) && touched.endDate,
                          })}
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          closeOnSelect
                          isValidDate={(current) => {
                            let customDate = Moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current >= Moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          value={values.endDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("endDate", dt);
                          }}
                        />
                      </div>
                      <UpdateScriptTypeAndRscFileInputSelectOrUpload
                        setFieldValue={setFieldValue}
                        setUploadScriptFile={setUploadScriptFile}
                        onUploadFile={onUploadFile}
                        selectScriptResult={selectScriptResult}
                        selectScriptFile={values.selectScriptFile}
                        uploadScriptFile={uploadScriptFile}
                        DeleteSelectFile={DeleteSelectFile}
                        setUploadRscFile={setUploadRscFile}
                        selectRscFile={values.selectRscFile}
                        uploadRscFile={uploadRscFile}
                        rscFileResult={rscFileResult}
                        refreshScript={refreshScript}
                        refreshRsc={refreshRsc}
                        errors={errors}
                        touched={touched}     
                        />

                      <div className="d-flex align-items-center mt-5 mb-5 ms-3">
                        <input
                          className="form-check-input me-5"
                          type="checkbox"
                          id='isLatestUpdate'
                          name="isLatestUpdate"
                          disabled={Boolean(values.endDate)}
                          onChange={(e: any) => {
                            setFieldValue("isLatestUpdate", e.target.checked);
                          }}
                        />
                        <label className="form-label mt-2 ms-2 fs-4 fw-bold">
                          Is latest update?
                        </label>
                      </div>

                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2">
                          <button
                            className="btn btn-primary fs-4 col-6 mt-3 w-100"
                            type="submit"
                          >
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">SUBMIT</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>
    </div>
  );
};

export default CreateVersionPage;