import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
export async function getMachineSoftwareVersions() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query {
            getMachineSoftwareVersions {
               id
               version
            }
          }
        `,
      variables: {},
    },
  });
  return data?.data?.data?.getMachineSoftwareVersions;
}

export async function deleteMachineSoftwareVersion(
  versionId:string
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation deleteMachineSoftwareVersion ($versionId: String!){
            deleteMachineSoftwareVersion(
                deleteMachineSoftwareVersionInput: {
                  version_id: $versionId
                }
            ) 
          }
        `,
      variables: {
        versionId
      },
    },
  })
  return data?.data;
}

export async function updateMachineSoftwareVersionStatus(
  versionId:string,
  status:boolean
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateMachineSoftwareVersionStatus ($versionId: String!,$status: Boolean!){
            updateMachineSoftwareVersionStatus(
                updateMachineSoftwareVersionStatusInput: {
                  versionId: $versionId,
                  isArchive: $status
                }
            ) 
          }
        `,
      variables: {
        versionId,
        status
      },
    },
  })
  return data?.data?.data?.updateMachineSoftwareVersionStatus;
}